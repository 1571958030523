import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { SearchIcon, CursorClickIcon } from "@heroicons/react/outline";
import moment from "moment";

import CircleMarkerSvg from "../report/CircleMarkerSvg";
import dummyGeojson from "../../util/dummyGeojson";

const MainSidebar = ({ map, inspections, zoom }) => {
  // if there is previous zoom level passed down, fly to it and reset the pitch to 0
  useEffect(() => {
    if (!map) {
      return;
    }
    if (zoom) {
      map.flyTo({
        zoom,
        pitch: 0,
      });
    }
  }, [map, zoom]);

  // hide 3d buildings, show inspection markers
  useEffect(() => {
    if (!map) return;
    map.setLayoutProperty("add-3d-buildings", "visibility", "none");
    map.setLayoutProperty("selected-feature-fill", "visibility", "none");

    map.setLayoutProperty("water-tanks-circle", "visibility", "visible");

    if (map.getSource("selected-feature")) {
      map.getSource("selected-feature").setData(dummyGeojson);
    }

    // reset 3d buildings style
    map.setPaintProperty("add-3d-buildings", "fill-extrusion-color", "#ccc");
  }, [map]);

  let dataUpdatedAt;
  let dataUpdatedAtFromNow;
  let dataUpdatedAtDisplay;

  if (inspections) {
    dataUpdatedAt = inspections.properties.dataUpdatedAt;
    dataUpdatedAtFromNow = moment(dataUpdatedAt).fromNow();
    dataUpdatedAtDisplay = moment(dataUpdatedAt).format("MMM DD, hh:mm a");
  }

  return (
    <div className="px-4">
      <div className="mb-4">
        <div className="mb-3">
          Keep your building up to code and avoid penalties by using our
          interactive map.
        </div>
        <div>
          We track the most recent cleaning of the Drinking Water Tank(s) at
          your building using data from the New York City Department of Health.
        </div>
      </div>
      <div className="px-2 py-1 mb-3 bg-gray-100 border border-gray-200 rounded">
        <div className="mb-1 text-sm font-semibold text-aptred">
          Most Recent Inspection Reporting Year
        </div>
        <div className="flex items-center">
          <CircleMarkerSvg inspectionStatus="current" />
          <div className="ml-1 text-sm">2025</div>
        </div>
        <div className="flex items-center">
          <CircleMarkerSvg inspectionStatus="previous" />
          <div className="ml-1 text-sm">2024</div>
        </div>
        <div className="flex items-center">
          <CircleMarkerSvg />
          <div className="ml-1 text-sm">2023 or earlier</div>
        </div>
        {dataUpdatedAtFromNow && (
          <div className="mt-1 mb-1 text-xs font-light text-gray-600">
            Data current as of{" "}
            <span className="italic">{dataUpdatedAtFromNow}</span> (
            {dataUpdatedAtDisplay})
          </div>
        )}
      </div>
      <div className="flex items-center mb-2 text-gray-800">
        <div className="w-8 mr-2 text-aptblue">
          <SearchIcon className="w-5 mx-auto" />
        </div>
        <div className="text-sm ">Search for a building by address or name</div>
      </div>

      <div className="flex items-center mb-2">
        <div className="flex-shrink-0 w-8 mr-2 text-aptblue">
          <CursorClickIcon className="w-6 mx-auto" />
        </div>
        <div className="mb-2 text-sm">
          Click a circle on the map to see a report of recent water tank
          inspections.
        </div>
      </div>

      <hr className="mb-3" />
      <div className="flex flex-col items-center">
        <div className="flex-grow text-sm text-aptred">Brought to you by</div>
        <a href="https://www.americanpipeandtank.com" aria-current="page">
          <img
            src="https://www.americanpipeandtank.com/wp-content/themes/apt/assets/images/apt-logo.png"
            className="flex-shrink-0 w-28"
            loading="lazy"
            alt="American Pipe &amp; Tank Logo"
            srcSet="https://www.americanpipeandtank.com/wp-content/themes/apt/assets/images/apt-logo-p-500.png 500w, https://www.americanpipeandtank.com/wp-content/themes/apt/assets/images/apt-logo.png 747w"
            sizes="(max-width: 479px) 100px, (max-width: 767px) 120px, (max-width: 991px) 190px, 17vw"
          />
        </a>
      </div>
    </div>
  );
};

MainSidebar.propTypes = {
  map: PropTypes.object,
  allGeometries: PropTypes.object,
};

export default MainSidebar;
